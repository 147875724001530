@font-face {
  src: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/142996/polar.otf");
  font-family: "Polar";
}



#theSlider {
  background: #000;
}

svg {
  display: block;
  overflow: visible;
}

#store-slider .slider-container {
  position: relative;
  height: inherit;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: all-scroll;
}

#store-slider .slider-control {
  z-index: 2;
  position: absolute;
  top: 0;
  width: 12%;
  height: inherit;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
  will-change: opacity;
  opacity: 0;
}
#store-slider .slider-control.inactive:hover {
  cursor: auto;
}
#store-slider .slider-control:not(.inactive):hover {
  opacity: 1;
  cursor: pointer;
}
#store-slider .slider-control.left {
  left: 0;
  background: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, 0.18)), to(rgba(0, 0, 0, 0)));
  background: linear-gradient(to right, rgba(0, 0, 0, 0.18) 0%, rgba(0, 0, 0, 0) 100%);
}
#store-slider .slider-control.right {
  right: 0;
  background: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, 0)), to(rgba(0, 0, 0, 0.18)));
  background: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.18) 100%);
}

#store-slider .slider-pagi {
  position: absolute;
  z-index: 3;
  left: 50%;
  bottom: 2rem;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  font-size: 0;
  list-style-type: none;
}
#store-slider .slider-pagi__elem {
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: 2rem;
  height: 2rem;
  margin: 0 0.5rem;
  border-radius: 50%;
  border: 2px solid #fff;
  cursor: pointer;
}
#store-slider .slider-pagi__elem:before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  width: 1.2rem;
  height: 1.2rem;
  background: #fff;
  border-radius: 50%;
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
  -webkit-transform: translate(-50%, -50%) scale(0);
  transform: translate(-50%, -50%) scale(0);
}
#store-slider .slider-pagi__elem.active:before, .slider-pagi__elem:hover:before {
  -webkit-transform: translate(-50%, -50%) scale(1);
  transform: translate(-50%, -50%) scale(1);
}

#store-slider .slider {
  z-index: 1;
  position: relative;
  height: inherit;
}
#store-slider .slider.animating {
  -webkit-transition: -webkit-transform 0.5s;
  transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
  will-change: transform;
}
#store-slider .slider.animating .slide__bg {
  -webkit-transition: -webkit-transform 0.5s;
  transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
  will-change: transform;
}

#store-slider .slide {
  position: absolute;
  top: 0;
  width: 100%;
  height: inherit;
  overflow: hidden;
}
#store-slider .slide.active .slide__overlay,
#store-slider .slide.active .slide__text {
  opacity: 1;
  -webkit-transform: translateX(0);
  transform: translateX(0);
}
#store-slider .slide__bg {
  position: absolute;
  top: 0;
  left: -50%;
  width: 100%;
  height: inherit;
  background-size: cover;
  will-change: transform;
}
#store-slider .slide:nth-child(1) {
  left: 0;
}
#store-slider .slide:nth-child(1) .slide__bg {
  left: 0;
}
#store-slider .slide:nth-child(1) .slide__overlay-path {
  fill: rgba(0, 0, 0 , 0.35);
}
@media (max-width: 991px) {
  .slide:nth-child(1) .slide__text {
    background-color: rgba(0, 0, 0 , 0.35);
  }
}
.slide:nth-child(2) {
  left: 100%;
}
.slide:nth-child(2) .slide__bg {
  left: -50%;
  background-image: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/142996/3d-slider-2.jpg");
}
.slide:nth-child(2) .slide__overlay-path {
  fill: rgba(0, 0, 0 , 0.35);
}
@media (max-width: 991px) {
  .slide:nth-child(2) .slide__text {
    background-color: rgba(0, 0, 0 , 0.35);
  }
}
.slide:nth-child(3) {
  left: 200%;
}
.slide:nth-child(3) .slide__bg {
  left: -100%;
  background-image: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/142996/3d-slider-1.jpg");
}
.slide:nth-child(3) .slide__overlay-path {
  fill: rgba(0, 0, 0 , 0.35);
}
@media (max-width: 991px) {
  .slide:nth-child(3) .slide__text {
    background-color: rgba(0, 0, 0 , 0.35);
  }
}
.slide:nth-child(4) {
  left: 300%;
}
.slide:nth-child(4) .slide__bg {
  left: -150%;
  background-image: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/142996/3d-slider-4.jpg");
}
.slide:nth-child(4) .slide__overlay-path {
  fill: rgba(0, 0, 0 , 0.35);
}
@media (max-width: 991px) {
  .slide:nth-child(4) .slide__text {
    background-color: rgba(0, 0, 0 , 0.35);
  }
}
.slide__content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: inherit;
}
.slide__overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  height: inherit;
  min-height: 810px;
  -webkit-transition: opacity 0.2s 0.5s, -webkit-transform 0.5s 0.5s;
  transition: opacity 0.2s 0.5s, -webkit-transform 0.5s 0.5s;
  transition: transform 0.5s 0.5s, opacity 0.2s 0.5s;
  transition: transform 0.5s 0.5s, opacity 0.2s 0.5s, -webkit-transform 0.5s 0.5s;
  will-change: transform, opacity;
  -webkit-transform: translate3d(-20%, 0, 0);
  transform: translate3d(-20%, 0, 0);
  opacity: 0;
}
@media (max-width: 991px) {
  .slide__overlay {
    display: none;
  }
}
.slide__overlay path {
  opacity: 0.8;
}
.slide__text {
  position: absolute;
  width: 25%;
  bottom: 15%;
  left: 12%;
  color: #fff;
  -webkit-transition: opacity 0.5s 0.8s, -webkit-transform 0.5s 0.8s;
  transition: opacity 0.5s 0.8s, -webkit-transform 0.5s 0.8s;
  transition: transform 0.5s 0.8s, opacity 0.5s 0.8s;
  transition: transform 0.5s 0.8s, opacity 0.5s 0.8s, -webkit-transform 0.5s 0.8s;
  will-change: transform, opacity;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  opacity: 0;
}
@media (max-width: 991px) {
  .slide__text {
    left: 0;
    bottom: 0;
    width: 100%;
    height: 20rem;
    text-align: center;
    -webkit-transform: translateY(50%);
    transform: translateY(50%);
    -webkit-transition: opacity 0.5s 0.5s, -webkit-transform 0.5s 0.5s;
    transition: opacity 0.5s 0.5s, -webkit-transform 0.5s 0.5s;
    transition: transform 0.5s 0.5s, opacity 0.5s 0.5s;
    transition: transform 0.5s 0.5s, opacity 0.5s 0.5s, -webkit-transform 0.5s 0.5s;
    padding: 0 1rem;
  }
}
.slide__text-heading {
  font-family: "Polar", Helvetica, Arial, sans-serif;
  font-size: 5rem;
  margin-bottom: 2rem;
}
@media (max-width: 991px) {
  .slide__text-heading {
    line-height: 20rem;
    font-size: 3.5rem;
  }
}
.slide__text-desc {
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-size: 1.8rem;
  margin-bottom: 1.5rem;
}
@media (max-width: 991px) {
  .slide__text-desc {
    display: none;
  }
}
.slide__text-link {
  z-index: 5;
  display: inline-block;
  position: relative;
  padding: 0.5rem;
  cursor: pointer;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-size: 2.3rem;
  -webkit-perspective: 1000px;
  perspective: 1000px;
  color: #dd2030;
}
@media (max-width: 991px) {
  .slide__text-link {
    display: none;
  }
}
.slide__text-link:before {
  z-index: -1;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: inherit;
  background: #dd2030;
  -webkit-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
  -webkit-transform: rotateX(-85deg);
  transform: rotateX(-85deg);
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
  will-change: transform;
}
.slide__text-link:hover:before {
  -webkit-transform: rotateX(0);
  transform: rotateX(0);
}




.slider-container{
  height: 600px;
}
/*mobile*/
@media (max-width: 525px)
{

}
@media (min-width: 525px)
{

}
/*medium screens*/
@media (min-width: 768px)
{

}

/*desktop*/
@media (min-width: 992px)
{

}

/*large*/
@media (min-width: 1200px) {


}