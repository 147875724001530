/*all*/

.user-links>ul>li.active>a{
    color: #dd2030;
}

.slider-container{
    height: 600px;
}
.has-error{
    border: 1px solid #dd2030;
}

/*mobile*/
@media (max-width: 525px)
{

}
@media (min-width: 525px)
{

}
/*medium screens*/
@media (min-width: 768px)
{

}

/*desktop*/
@media (min-width: 992px)
{

}

/*large*/
@media (min-width: 1200px)
{


}