
#store-gallery .p-card {
    border: none

}

#store-gallery .p-carousel {
    background: #efefef;
}


#store-gallery .p-details {
    padding: 10px;
    border-radius: 0;
    background: #efefef;
    color: #111;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px
}



#store-gallery .spec span {
    font-size: 13px
}


#store-gallery .spec h6 {
    font-size: 16px;
    font-weight: 500
}


#store-gallery .carousel-indicators li {
    box-sizing: content-box;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    width: 12px;
    height: 12px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity .6s ease
}

#store-gallery .line {
    background-color: #fff;
    margin-top: 4px;
    margin-bottom: 4px;
    height: 0.2px
}



/*mobile*/
@media (max-width: 525px)
{

}
@media (min-width: 525px)
{

}
/*medium screens*/
@media (min-width: 768px)
{

}

/*desktop*/
@media (min-width: 992px)
{

}

/*large*/
@media (min-width: 1200px)
{

}